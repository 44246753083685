import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/hero-img.svg';

const UpdateBanner = ({ onClose }) => {
        // State to manage the shake animation
    const [shake, setShake] = useState(false);

        // useEffect hook to manage the shake animation interval
    useEffect(() => {
        const shakeInterval = setInterval(() => {
            setShake(prevShake => !prevShake); // Toggle shake state
        }, 2000); // Adjust interval as needed

        return () => clearInterval(shakeInterval); // Cleanup interval on unmount
    }, []);

    const bannerStyles = {
        container: {
            top: '45px',
            backgroundColor: '#9b59b6', // Purple color
            color: '#fff', // White text
            padding: '20px',
            textAlign: 'center',
            borderRadius: '10px',
            margin: '20px auto 40px', // Added margin at the bottom
            width: '80%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            animation: shake ? 'shake 0.5s cubic-bezier(.36,.07,.19,.97)' : 'none', // Apply shake animation conditionally
        },
        closeButton: {
            position: 'absolute',
            top: '35px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '16px',
            color: '#fff', // White close button
            cursor: 'pointer',
        },
        text: {
            fontSize: '1.2em',
            lineHeight: '1.5',
        }
    };

    return (
        <div id="update-banner" style={bannerStyles.container}>
            <button style={bannerStyles.closeButton} onClick={onClose}>✖</button>
            <p style={bannerStyles.text}>
                <strong>UPDATE: We are still working on administration, application sanatization, and more updates before the official launch. I do apologize, my goal is to push out a product that will help others and I don't feel its quite ready.</strong><br />
                Stay tuned for an amazing new feature that will positively change the way you experience this site. Exciting! There is no estimated due date but as it nears completion we will announce the ETA. <b>WAS: February 15, 2024 </b>. Thank you so much for your patience.
            </p>
            <p style={bannerStyles.text}>
                <strong>We Have Recently Updated Our Site Using AWS for Better Security</strong><br />
                My appologies for any issues that this may have caused. Thank you for your patience while we strive for to make the world a better place.
            </p>
        </div>
    );
}

const Hero = () => {
        // State to manage whether to show the banner
    const [showBanner, setShowBanner] = useState(true);

        // Function to handle closing the banner
    const handleCloseBanner = () => {
        setShowBanner(false); // Sets showBanner to false, hiding the banner
    }
    
    // JSX to render the Hero component
    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>
                {showBanner && <UpdateBanner onClose={handleCloseBanner} />}
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                Non-Profit Information Technology Solutions for <b>FREE</b>.
                            </h1>
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">We are highly motivated, skilled developers, and professionals dedicated to delivering the best solutions for customers and businesses of all kinds.</div>
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn more
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                            </div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-4/4" data-aos="fade-up" data-aos-delay="1500">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={heroImg} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hero;
